<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action" v-if="selectedVariant && backUpVariant && JSON.stringify(selectedVariant) !== JSON.stringify(backUpVariant)">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Өөрчлөлт хадгалах</div>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="20" :offset="2">
        <custom-header :title="selectedVariant ? selectedVariant.variant_name : ''"/>
        <el-form label-position="top" @submit.native.prevent="onPressSave">
          <el-row :gutter="20">
            <el-col :span="8">
              <!-- product info section -->
              <div class="panel" v-if="productData">
                  <div class="panel-item">
                    <div class="panel-content">
                      <el-row :gutter="10" type="flex" align="middle">
                        <el-col :span="8">
                          <!-- <div class="image-holder">
                            <img :src="productData.image[0]">
                          </div> -->
                          <el-image
                            class="image-holder"
                            :src="productData.image[0]"
                            fit="contain"></el-image>
                        </el-col>
                        <el-col :span="16">
                          <div class="mb5">
                            <span class="mr5 text-color-primary">{{productData.name_mon}}</span>
                            <el-tag :type="productData.status === 'active' ? 'success' : 'error'" style="line-height: 24px; height: 24px">{{productData.status === 'active' ? 'Идэвхитэй' : 'Идэвхигүй'}}</el-tag>
                          </div>
                          <div class="mb5"  v-if="productData.variants.length != 0">
                            <span class="text-color-secondary">
                              {{ productData.variants.length }} сонголт
                            </span>
                          </div>
                          <div>
                            <router-link class="text-primary" to="/products">Бүтээгдэхүүнрүү буцах</router-link>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
              </div>
              <!-- Variants section -->
              <div class="panel" v-if="productData && productData.variants">
                <div class="panel-item">
                  <header>Сонголтууд</header>
                </div>
                <div class="variant-list">
                  <div class="variant-item" v-for="(variant, index) in productData.variants" :key="index" :class="selectedVariant.variant_id === variant.variant_id ? 'active' : ''">
                    <el-row :gutter="10" type="flex" align="middle" @click.native="onPressVariant(variant)">
                      <el-col :span="6">
                        <el-image
                          class="image-holder"
                          :src="variant.variant_image && Array.isArray(variant.variant_image) && variant.variant_image.length !==0 ?  variant.variant_image[0] :  variant.variant_image"
                          fit="contain"></el-image>
                      </el-col>
                      <el-col :span="18">
                        <span>{{variant.variant_name}}</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="16">
              <!-- Options section -->
              <div class="panel" v-if="selectedVariant">
                  <div class="panel-item">
                    <header>Төрөл</header>
                    <div class="panel-content">
                      <el-form-item v-for="(item, index) in selectedVariant.option" :key="index" :label="item.name">
                        <el-input :disabled="true" v-model="item.value" key="index"></el-input>
                      </el-form-item>
                      <div class="mb10">Зураг /төрөл/</div>
                      <el-upload
                        list-type="picture-card"
                        accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                        :disabled="true"
                        action=""
                        :file-list="tempImages"
                        :auto-upload="false"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :on-change="getFile">
                        <span>1020x1020</span>
                      </el-upload>
                      <loader :active="isUploading" />
                    </div>
                  </div>
              </div>
              <!-- Status section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <header>Төлөв</header>
                  <el-select :disabled="true" v-model="selectedVariant.variant_status" class="block">
                    <el-option
                      v-for="item in statusOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- Pricing section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <header>Үнэ</header>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Зарах үнэ">
                        <el-input :disabled="true" v-model="selectedVariant.variant_sellPrice" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Татах үнэ" :inline-message="true" :show-message="true">
                        <el-input :disabled="true" v-model="selectedVariant.variant_getPrice" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="selectedVariant.variant_sellPrice!==undefined && selectedVariant.variant_sellPrice!=='' && selectedVariant.variant_getPrice!==undefined && selectedVariant.variant_getPrice!==''">
                      <el-row class="mt30">
                        <el-col :span="12">
                          <div class="text-color-secondary">Зөрүү</div>
                          <div class="text-color-primary">{{generateCurrency(parseFloat(selectedVariant.variant_sellPrice) - parseFloat(selectedVariant.variant_getPrice))}}</div>
                        </el-col>
                        <el-col :span="12">
                          <div class="text-color-secondary">Ашиг</div>
                          <div class="text-color-primary">{{parseFloat(((parseFloat(selectedVariant.variant_sellPrice)-parseFloat(selectedVariant.variant_getPrice))*100)/parseFloat(selectedVariant.variant_getPrice)).toFixed(2)}}%</div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Group order section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Групп захиалга</header>
                </div>
                <div class="panel-item">
                  <strong>24</strong> цагийн дотор <strong>10</strong> хүн <strong>20%</strong> хямдралтай авах боломжтой
                </div>
              </div>
              <!-- Inventory section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <header>Агуулах</header>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Доод үлдэгдэл">
                        <el-input :disabled="true" type="number" v-model="selectedVariant.threshold_number" :min="0">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="SKU">
                        <el-input  type="text" disabled :value="selectedVariant.variant_sku">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Barcode">
                        <el-input type="text" v-model="selectedVariant.variant_barcode">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="7">
                      <el-form-item label="Бүтээгдэхүүний дуусах хугацаа">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="selectedVariant.variant_endDate"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5" class="pt25">
                      <el-button type="success" :disabled="isDisabledButton('barCode')" circle icon="el-icon-check" @click="setVariantBarCodeAndExpiredDate(selectedVariant.variant_id)"></el-button>
                    </el-col>
                  </el-row>
                </div>
                <div class="panel-item">
                  <div class="mb20">
                    <el-checkbox :disabled="true" v-model="selectedVariant.sell_check">Агуулахад байхгүй ч зарах</el-checkbox>
                  </div>
                </div>
                <div class="panel-item" v-if="currentWarehouse.length !== 0">
                  <el-table
                    class="mb20"
                    v-loading="isLoadWarehouse"
                    @cell-click="onClickWarehouse"
                    :data="warehouseList ? warehouseList : null">
                    <el-table-column
                      label="Агуулахийн нэр"
                      style="flex:1"
                      prop="name_mon">
                    </el-table-column>
                    <el-table-column
                      label="Үлдэгдэл"
                      style="flex:1"
                      align="center">
                      <template slot-scope="scope">
                        <span :class="scope.row.count > 0 ? '' : 'text-danger'">{{scope.row.count}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Татан авалт"
                      style="flex:1"
                      align="center">
                      <template slot-scope="scope">
                        <el-popover
                          placement="bottom"
                          title="Татан авалт"
                          width="200"
                          trigger="click">
                          <div class="transfer-info">
                            <el-row :gutter="10" type="flex" align="middle" v-for="(transfer, index) in scope.row.transfers" :key="index">
                              <el-col :span="12" >
                                <router-link :to="{ name : 'editTransfer', params: { transfer_id: transfer.transfer_id }}" class="text-primary">#{{transfer.transfer_id}} - {{transfer.transferCount}}</router-link>
                              </el-col>
                            </el-row>
                          </div>
                          <span slot="reference" to="" class="text-primary cursor-pointer">{{scope.row.transferCount}}</span>
                        </el-popover>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div v-if="selectedIndex !== null && warehouseList[selectedIndex]">
                    <header>Агуулах : <span style="color: #409EFF">/{{ this.selectedWarehouseName }}/</span>-байршил</header>
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="Тавиур">
                          <el-input  type="text" v-model="warehouseList[selectedIndex].rack">
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Давхар">
                          <el-input  type="text" v-model="warehouseList[selectedIndex].shelf">
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Нүд">
                          <el-input  type="text" v-model="warehouseList[selectedIndex].unit">
                          </el-input>
                        </el-form-item>
                      </el-col>
                       <el-col :span="6">
                        <el-form-item class="pt25">
                          <el-button type="success" :disabled="isDisabledButton('position', warehouseList[selectedIndex])" circle icon="el-icon-check" @click="setVariantRackPosition(warehouseList[selectedIndex], selectedVariant)"/>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <el-button v-if="changesData.length != 0" type="text" class="text-primary" @click="dialogTableVisible = true">Агуулга түүх харах</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- Inventory history dialog -->
    <el-dialog title="Агуулга түүх" :visible.sync="dialogTableVisible" top="20px">
      <el-table :data="changesData">
        <el-table-column property="created_at" label="Он\Сар\Өдөр" width="150"></el-table-column>
        <el-table-column property="user_name" label="Өөрчлөгч" width="100"></el-table-column>
        <el-table-column property="warehouse_name" label="Агуулах" width="100"></el-table-column>
        <el-table-column label="Өөрчлөлт" width="100">
          <template slot-scope="scope">
            <span :class="(scope.row.old_count - scope.row.new_count) > 0 ? 'text-danger' : 'text-success'">{{((scope.row.old_count - scope.row.new_count) * -1) > 0 ? "+" + ((scope.row.old_count - scope.row.new_count) * -1) : ((scope.row.old_count - scope.row.new_count) * -1) }}</span>
          </template>
        </el-table-column>
        <el-table-column property="new_count" label="Боломжтой" width="100"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Өөрчлөлтүүдийг хадгалах уу"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>Хэрвээ та устгах товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Үргэлжлүүлэх</el-button>
        <el-button type="danger" @click="onPressClose">Устгах</el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>

<script>
import services from '../../../helpers/services'
import helper from '../../../helpers/helper'
import { handlers } from '../../../helpers/custom'
import CustomHeader from '../../../components/common/customHeader'
import { getUserName } from '../../../utils/auth'
import Loader from '../../../components/common/loader'
export default {
  name: 'StockDetail',
  components: {
    CustomHeader,
    Loader
  },
  data () {
    return {
      productData: null,
      tempImages: [],
      selectedVariant: {
        created_at: '',
        variant_id: '',
        variant_endDate: '',
        variant_barcode: '',
        variant_getPrice: 0,
        variant_sellPrice: 0,
        product_id: '',
        variant_name: '',
        variant_sku: '',
        variant_status: '',
        variant_image: '',
        threshold_number: '',
        sell_check: false,
        option: [],
        warehouse: [{
          count: 0,
          warehouse_name: 'default',
          rack: '',
          shelf: '',
          unit: ''
        }]
      },
      backUpVariant: {
        variant_getPrice: 0,
        variant_sellPrice: 0,
        variant_status: '',
        variant_image: ''
      },
      changesData: [],
      statusOption: [],
      warehouseList: [],
      currentWarehouse: [],
      selectedWarehouse: null,
      selectedIndex: null,
      selectedWarehouseName: null,
      dialogTableVisible: false,
      gridData: null,
      confirmLeaveForm: false,
      dialogImageUrl: '',
      isUploading: false,
      isLoading: false,
      dialogVisible: false,
      isLoadWarehouse: false,
      userName: null
    }
  },
  mounted () {
    if (this.$route.params.variant_id) {
      this.getVariants(this.$route.params.variant_id)
      this.getStatusOptions()
      getUserName().then(email => {
        this.userName = email.split('@')[0]
      })
    }
  },
  methods: {
    setVariantBarCodeAndExpiredDate (id) {
      const body = {
        variant_id: id,
        expired_date: this.selectedVariant.variant_endDate,
        variant_barcode: this.selectedVariant.variant_barcode
      }
      services.setVariantBarCodeAndExpiredDate(body).then((response) => {
        if (response.status === 'success') {
          this.alertReporter(response.status === 'success' ? 'Амжилттай' : 'Уучлаарай', response.message, response.status)
        }
      })
    },

    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    onClickWarehouse (data) {
      this.selectedWarehouse = data.warehouse_id
      this.warehouseList.forEach((element, index) => {
        if (element.warehouse_id === data.warehouse_id) {
          this.selectedIndex = index
          this.selectedWarehouseName = element.name_mon
        }
      })
    },

    setVariantRackPosition (warehouse, selectedVariant) {
      const body = {
        variant_id: selectedVariant.variant_id,
        warehouse_id: warehouse.warehouse_id,
        rack: warehouse.rack,
        shelf: warehouse.shelf,
        unit: warehouse.unit
      }
      services.setVariantRackPosition(body).then((response) => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, response.status)
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа гарлаа', response.message, response.status)
        }
      })
    },

    generateWarehouse (currentData, variantId) {
      var tempWarehouseList = []
      this.isLoadWarehouse = true
      if (currentData && currentData.length !== 0) {
        const query = '?from=' + 0 + '&size=' + 999
        services.getWarehouses(query).then(response => {
          if (response && response.datas && response.datas.length !== 0) {
            response.datas.forEach((element) => {
              var tempWarehouse = null
              if (currentData && currentData.length !== 0) {
                currentData.forEach((secondElement) => {
                  if (element.warehouse_id === secondElement.warehouse_id) {
                    tempWarehouse = {
                      warehouse_id: secondElement.warehouse_id,
                      name_mon: element.name_mon,
                      name_eng: element.name_eng,
                      count: secondElement.count,
                      rack: secondElement.rack,
                      shelf: secondElement.shelf,
                      unit: secondElement.unit
                    }
                  }
                })
              }
              if (tempWarehouse === null) {
                tempWarehouse = {
                  warehouse_id: element.warehouse_id,
                  name_mon: element.name_mon,
                  name_eng: element.name_eng,
                  count: 0,
                  rack: '',
                  shelf: '',
                  unit: ''
                }
              }
              tempWarehouseList.push(tempWarehouse)
            })
          } else {
            tempWarehouseList = currentData
          }
        })
      } else {
        tempWarehouseList = currentData
      }
      this.getTransfers(tempWarehouseList, variantId)
    },
    defaultVariant (variant) {
      var newData = {
        variant_status: variant.variant_status ? variant.variant_status : 'active',
        sell_check: variant.sell_check !== undefined ? variant.sell_check : false,
        variant_sellPrice: variant.variant_sellPrice ? variant.variant_sellPrice : 0,
        variant_getPrice: variant.variant_getPrice ? variant.variant_getPrice : 0,
        variant_barcode: variant.variant_barcode ? variant.variant_barcode : '',
        variant_endDate: variant.expired_date ? variant.expired_date : '',
        threshold_number: variant.threshold_number ? variant.threshold_number : 0,
        variant_sku: variant.variant_sku,
        variant_image: variant.variant_image,
        variant_name: variant.variant_name,
        variant_id: variant.variant_id,
        product_id: variant.product_id,
        option: variant.option
      }
      this.currentWarehouse = variant.warehouse
      this.generateWarehouse(variant.warehouse, variant.variant_id)
      this.setImages(variant.variant_image)
      return newData
    },
    getStatusOptions () {
      this.statusOption = [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }]
    },
    onPressVariant (data) {
      const newVariant = this.defaultVariant(data)
      this.selectedVariant = newVariant
      this.backUpVariant = JSON.parse(JSON.stringify(newVariant))
    },
    onPressSave () {
      var tempImages = []

      var variant = JSON.parse(JSON.stringify(this.selectedVariant))
      variant.variant_getPrice = parseFloat(variant.variant_getPrice)
      variant.variant_sellPrice = parseFloat(variant.variant_sellPrice)
      variant.warehouse = this.warehouseList
      this.tempImages.forEach((element) => {
        tempImages.push(element.url)
      })
      variant.variant_image = tempImages
      var body = {
        variant_id: this.selectedVariant.variant_id,
        variant: variant
      }
      this.isLoading = true
      services.updateVariant(body).then((response) => {
        this.isLoading = false
        this.alertReporter(response.status === 'success' ? 'Амжилттай' : 'Уучлаарай', response.message, response.status)
        if (response.status === 'success') {
          this.$router.go(-1)
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    getVariants (variantId) {
      this.isLoading = true
      services.getProductByVariantId(variantId).then((response) => {
        this.isLoading = false
        if (response.status === 'success') {
          this.productData = response.data
          response.data.variants.forEach(element => {
            if (element.variant_id === variantId) {
              const newVariant = this.defaultVariant(element)
              this.selectedVariant = newVariant
              this.backUpVariant = JSON.parse(JSON.stringify(newVariant))
            }
          })
        }
      })
    },
    getTransfers (tempData, variantId) {
      var tempWarehouseList = tempData
      services.getOneVariant(variantId).then((response) => {
        this.isLoadWarehouse = false
        if (response.status === 'success' && response.data) {
          tempWarehouseList.forEach((element) => {
            var tempWarehouse = null
            response.data.warehouse.forEach((secondElement) => {
              if (element.warehouse_id === secondElement.warehouse_id) {
                tempWarehouse = {
                  transferCount: secondElement.transferCount,
                  transfers: secondElement.transfers
                }
              }
            })
            if (!tempWarehouse) {
              tempWarehouse = {
                transferCount: 0,
                transfers: []
              }
            }
            Object.assign(element, tempWarehouse)
          })
          this.changesData = response.data.changes
        }
        this.warehouseList = tempWarehouseList
      })
    },
    setImages (images) {
      var tempImages = []
      if (images && Array.isArray(images)) {
        images.forEach(element => {
          tempImages.push({
            url: element
          })
        })
      } else {
        tempImages.push({
          url: images
        })
      }
      this.tempImages = tempImages
    },
    handleRemove (file, fileList) {
      var tempId = 0
      this.tempImages.forEach((element, index) => {
        if (element.uid === file.uid) {
          tempId = index
        }
      })
      this.tempImages.splice(tempId, 1)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getFile (file, fileList) {
      handlers.checkImageSize(file, true, 1020, 1020).then(() => {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              this.tempImages.push({
                url: response.url
              })
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file)
        this.$message.error(error.message)
      })
    },

    isDisabledButton (type, warehouseListIndex) {
      if (type === 'barCode') {
        let isDisabled = true
        if (this.selectedVariant.variant_barcode !== '' || this.selectedVariant.variant_endDate !== '') {
          isDisabled = false
        } else {
          isDisabled = true
        }
        return isDisabled
      } else if (type === 'position') {
        let isDisabled = true
        if (warehouseListIndex.rack !== '' || warehouseListIndex.unit !== '' || warehouseListIndex.shelf !== '') {
          isDisabled = false
        } else {
          isDisabled = true
        }
        return isDisabled
      }
    },
    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.go(-1)
    }
  }
}
</script>
